<script setup lang="ts">
import { defineProps, defineEmits } from "vue";
import { Button, type ButtonVariants } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";

const props = withDefaults( defineProps<{
  title?: string;
  description?: string;
  confirmButtonText?: string;
  cancelButtonText?: string;
  confirmButtonVariant?: ButtonVariants["variant"];
}>(), {
  title: "Confirm",
  description: "Are you sure you want to proceed?",
  confirmButtonText: "Confirm",
  cancelButtonText: "Cancel",
  confirmButtonVariant: "default",
} );

const localization = useLocalization();
const title = localization.formatHeading( props.title );

const open = defineModel<boolean>( "open" );

const emits = defineEmits( ["confirm", "cancel"] );

const onConfirm = () => {
  emits( "confirm" );
  open.value = false;
};

const onCancel = () => {
  emits( "cancel" );
  open.value = false;
};
</script>

<template>
  <Dialog v-model:open="open">
    <DialogContent class="sm:max-w-md">
      <DialogHeader>
        <DialogTitle>{{ title }}</DialogTitle>
        <DialogDescription>{{ description }}</DialogDescription>
      </DialogHeader>
      <DialogFooter class="sm:justify-end">
        <Button
          variant="ghost"
          @click="onCancel">
          {{ cancelButtonText }}
        </Button>
        <Button
          :variant="confirmButtonVariant"
          @click="onConfirm">
          {{ confirmButtonText }}
        </Button>
      </DialogFooter>
    </DialogContent>
  </Dialog>
</template>
